<template>
  <v-container class="news-entry glowing-border default-bottom-margin">
    <p class="news-entry__date">{{ $props.date }}</p>
    <h1 class="mb-3">{{ $props.title }}</h1>
    <!-- <p class="news-text">{{ $props.text }}</p> -->
    <p class="news-entry__text" v-html="$props.text"></p>
  </v-container>
</template>

<script>
export default {
  name: 'NewsEntry',
  props: {
    date: { type: String, required: false, default: "" },
    title: { type: String, required: true },
    text: { type: String, required: true },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style-variables.scss";

.news-entry {
  background-color: $content-background-color;

  &__date {
    font-size: 12px;
    margin: 0;
  }

  &__text {
    white-space: pre-line;
  }
}
</style>