<template>
    <v-container>
      <news-entry
        :title="'404 - ' + $t('notFound')"
        :text="$t('notFoundDetails')"
      />
    </v-container>
</template>

<script>
import newsEntry from '@/components/news-entry.component.vue';

export default {
    name: "NotFound",
    metaInfo: {
      meta: [
        { name: 'description', content: '404 - Page cannot be found.'},
        { name: 'robots', content: 'noindex, follow'}
      ]
    },
    components: {
        newsEntry,
    }
}
</script>